import Helper from './../components/Helper'

let callDashboard = (dispatch) => {
    Helper.request(dispatch, 'GET', 'dashboard', null, (data) => {
        dispatch({
            type: 'DASHBOARD',
            payload: {
                orders: data.orders
            }
        })
    }, null, false)
}

export { callDashboard }