import Helper from "./../../components/Helper"
import history from './../../routes/history'
import { toast } from 'react-toastify'

let callConfigProjectSportOfficeList = (dispatch) => {
    let pg = Helper.httpGet('pg', '1')
    Helper.request(dispatch, 'GET', 'config/project-sport-office?pg=' + pg, null, (data) => {
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_OFFICE_LIST',
            payload: {
                rows: data.rows,
                pagination: data.pagination,
                total: data.total,
            }
        })
    }, null, false)
}

let callConfigProjectSportOfficeDetail = (id) => (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/project-sport-office/' + id, null, (data) => {
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_OFFICE_EDIT',
            payload: {
                name: data.name,
                description: data.description,
                formation: data.formation,
                assignment: data.assignment,
                activity: data.activity,
                sports: data.sports,
                active: data.active,
            }
        })
    }, null, false)
}

let callConfigProjectSportOfficePost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'config/project-sport-office', data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_OFFICE_EDIT',
            payload: {
                name: '',
                description: '',
                formation: '',
                assignment: '',
                activity: '',
                sports: [],
                active: true,
            }
        })
        history.push('/config/project-sport-office')
    })
}

let callConfigProjectSportOfficePut = (id, data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/project-sport-office/' + id, data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_OFFICE_EDIT',
            payload: {
                name: '',
                description: '',
                formation: '',
                assignment: '',
                activity: '',
                sports: [],
                active: true,
            }
        })
        history.push('/config/project-sport-office')
    })
}

let callConfigProjectSportOfficeDelete = (id) => (dispatch) => {
    Helper.request(dispatch, 'DELETE', 'config/project-sport-office/' + id, null, (data) => {
        toast.success(data.msg)
        dispatch(callConfigProjectSportOfficeList);
    })
}


export { callConfigProjectSportOfficeList, callConfigProjectSportOfficeDetail, callConfigProjectSportOfficePost, callConfigProjectSportOfficePut, callConfigProjectSportOfficeDelete }