import Helper from "./../components/Helper"
import { callAuthGet } from "./Auth"

let setLoader = (loader) => (dispatch) => {
    dispatch({
        type: 'LOADER',
        payload: {
            loader: loader,
        }
    })
}

let sessionStart = (dispatch) => {
    if (!localStorage.getItem('token')) {
        Helper.request(dispatch, 'POST', 'token', null, (data) => {
            localStorage.setItem('token', data.token)
            dispatch(callAuthGet)
        }, null, false)
        return;
    }

    dispatch(callAuthGet)
}

let callConfigGet = (dispatch) => {
    Helper.request(dispatch, 'GET', 'config', null, (data) => {
        document.title = data.headTitle
        document.getElementById('favicon').href = data.faviconA
        dispatch({
            type: 'CONFIG',
            payload: {
                logoA: data.logoA,
                logoB: data.logoB,
                logoEloss: data.logoEloss,
                bgLoginA: data.bgLoginA,
                ip: data.ip,
            }
        })
    }, null, false)
}

let callConfigMenuGet = (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/menu', null, (data) => {
        dispatch({
            type: 'MENU',
            payload: {
                categories: data.categories,
            }
        })
    }, null, false)
}

export { setLoader, callConfigGet, sessionStart, callConfigMenuGet }