import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { callConfigProjectStatusDelete, callConfigProjectStatusList } from "./../../../actions/Config/ProjectStatus";
import Helper from "./../../../components/Helper";
import Pagination from "./../../../components/Pagination";

class ConfigProjectStatusList extends Component {

    constructor(props) {
        super(props)

        let tab = [
            {
                description: 'Consulta',
                link: '/config/project-status',
                icon: 'fas fa-cog',
                active: true,
            },
            {
                description: 'Adicionar',
                link: '/config/project-status/add',
                icon: 'fas fa-plus',
                active: false,
            }
        ]

        props.handlerHeader({
            tab: tab,
            categoryId: 5,
            menuId: 10
        })

        this.state = {
            pg: Helper.httpGet('pg', '1')
        }

        props.dispatch(callConfigProjectStatusList)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.pg !== Helper.httpGet('pg', '1')) {
            nextProps.dispatch(callConfigProjectStatusList)
            return ({
                ...prevState,
                pg: Helper.httpGet('pg', '1'),
            })
        }
        return null
    }

    delete(id) {
        Swal.fire({
            title: 'Tem certeza que deseja deletar? Essa ação não poderá ser desfeita!',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.dispatch(callConfigProjectStatusDelete(id))
            }
        })
    }

    render() {
        return (
            <div className="card-custom">
                <div className="card-body">
                    <form action="/" className="box-search">
                        <div className="input-search d-flex">
                            <input type="text" className="form-control rounded-0" placeholder="Pesquise pelo nome" name="search" />
                            <button type="submit" className="btn btn-primary rounded-0"><i className="fal fa-search"></i></button>
                            <button type="button" className="btn btn-secondary rounded-0"><i className="fal fa-filter"></i></button>
                        </div>
                    </form>
                    <div className="list mt-4">
                        {this.props.store.configProjectStatus.list.rows.map((a, i) => (
                            <div className="list-item" key={'list-a-' + i}>
                                <div className="title d-flex align-items-center">
                                    <Link to={'/config/project-status/edit/' + a.id} className="link-access">{a.name}</Link>
                                </div>
                                <div className="content d-flex justify-content-between">
                                    <div className="description">
                                        <strong>Cadastro:</strong> {a.createdAt}
                                    </div>
                                    <div className="options d-flex flex-column p-2">
                                        <Link to={'/config/project-status/edit/' + a.id} className="btn btn-sm btn-info btn-list mb-1" title="Editar"><i className="fa fa-pencil fa-white mr-2"></i>Editar</Link>
                                        <button onClick={e => this.delete(a.id)} type="button" className="btn btn-sm btn-warning btn-list" title="Excluir"><i className="fa fa-trash fa-white mr-2" aria-hidden="true"></i>Excluir</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="clearfix mt-3 ml-3 mr-3">
                        <div className="float-left">{this.props.store.configProjectStatus.list.total} registro(s) encontrado(s).</div>
                        <div className="float-right"><Pagination pagination={this.props.store.configProjectStatus.list.pagination} to="/config/project-status" /></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ConfigProjectStatusList))