import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Loader from "./Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../styles/BaseIn.css'
import './../styles/BaseOut.css'
import Swal from 'sweetalert2'
import { callAuthDelete } from "./../actions/Auth";

class Header extends Component {
    logout() {
        Swal.fire({
            title: 'Deseja se desconectar do sistema?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.dispatch(callAuthDelete)
            }
        })
    }

    render() {
        if (this.props.store.first) {
            return <Loader show={true} />
        }

        let i
        let menus = [];
        for (i = 0; i < this.props.store.headerIn.categories.length; i++) {
            if (this.props.store.headerIn.categories[i].id === this.props.categoryId) {
                menus = this.props.store.headerIn.categories[i].menus
                break;
            }
        }

        return (
            <>
                <Loader show={this.props.store.loader} />
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                {this.props.store.session.logged ?
                    <>
                        <header className="header-desktop d-none d-sm-block">
                            <div className="d-flex align-items-center justify-content-between h-100">
                                <div className="logo mr-4">
                                    <Link to={'/dashboard'}><img className="img-fluid" src={this.props.store.config.logoA} alt="Eloss" /></Link>
                                </div>
                                <div className="flex-fill"></div>
                                <div className="sep"></div>
                                <div className="options d-flex justify-content-end align-items-center">
                                    <div className="user d-flex align-items-center px-3">
                                        <img className="rounded-circle photo" src={this.props.store.session.photo} alt="Eloss" />
                                        <i className="fal fa-angle-down ml-2"></i>
                                        <div className="user-f">
                                            <div className="d-flex user-f-h align-items-center justify-content-between">
                                                <div className="user-f-name">{this.props.store.session.name ? this.props.store.session.name : 'Suporte'}</div>
                                                <div className="user-f-photo">
                                                    <img className="rounded-circle" src={this.props.store.session.photo} alt="Eloss" />
                                                </div>
                                            </div>
                                            {/*this.props.store.session.name ?
                                        <div className="user-f-menu d-flex align-items-center">
                                            <a href="<?= url('AccountProfile') ?>" className="user-f-menu-item">Meus Dados</a>
                                    </div> : <></>*/}
                                            <div className="user-f-menu out d-flex align-items-center">
                                                <a href="#a" onClick={(e) => { e.preventDefault(); this.logout() }} className="user-f-menu-item">Sair</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sep"></div>
                                    <div className="notification d-flex align-items-center px-3">
                                        <i className="fal fa-bullhorn fa-2x"></i>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <header className="header-mobile d-block d-sm-none">
                            <div className="d-flex h-100 justify-content-between align-items-center">
                                <div className="photo d-flex justify-content-center align-items-center disable-select">
                                    <img className="rounded-circle" src={this.props.store.session.photo} alt="Eloss" />
                                    <div className="header-mobile-user ml-2">
                                        <div className="name">{this.props.store.session.name ? this.props.store.session.name : 'Suporte'}</div>
                                    </div>
                                </div>
                                <div className="line-sep"></div>
                                <div className="menu-mobile h-100 d-flex flex-column align-items-center justify-content-center disable-select">
                                    <i className="fa fa-bars fa-2x mb-1"></i>MENU
                                    <div className="menu-mobile-hide">
                                        <ul>
                                            {this.props.store.headerIn.categories.map((item, i) => {
                                                return (
                                                    <li className={this.props.categoryId === item.id ? 'active' : ''} key={'categories-a-' + i}>
                                                        <Link to={item.to}>
                                                            <i className={item.icon}></i>&nbsp;{item.description}
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                            <li>
                                                <a href="#a" onClick={(e) => { e.preventDefault(); this.logout() }} className="text-danger"><i className={'fas fa-sign-out-alt text-danger'}></i>&nbsp;Sair</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <section className="menu-desktop d-none d-sm-block">
                            <ul>
                                {this.props.store.headerIn.categories.map((item, i) => {
                                    return (
                                        <li className={this.props.categoryId === item.id ? 'active' : ''} key={'categories-b-' + i}>
                                            <Link to={item.to}>
                                                <i className={item.icon}></i>&nbsp;{item.description}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </section>

                        <section className="main">
                            <div className="sub-menu d-flex justify-content-stretch align-items-center">
                                <ul className="d-flex align-items-center">
                                    {menus.map((item, i) => {
                                        return (
                                            <li className={item.id === this.props.menuId ? 'active' : ''} key={'menu-a-' + i}>
                                                <Link to={item.to} className="d-flex align-items-center">
                                                    <i className={item.icon + ' mr-2'}></i>
                                                    <span>{item.description}</span>
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="page-tab">
                                <ul className="d-flex align-items-center">
                                    {this.props.tab.map((item, i) => {
                                        return (
                                            <li key={'tab-a-' + i} className={item.active ? 'active' : ''}>
                                                <Link to={item.link} className="d-flex align-items-center px-4">
                                                    <i className={item.icon + ' mr-2'}></i>
                                                    <span>{item.description}</span>
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            {this.props.children}
                            <div className="d-flex justify-content-between mt-3 ml-3 mr-3">
                                <div className="text-left">
                                    Desenvolvido por <a href="https://ericteixeira.dev" className="logo-anexus">ericteixeira.dev</a>
                                </div>
                                <div className="text-right">
                                    <span className="ml-2"><strong>Seu IP:</strong> {this.props.store.config.ip}</span>
                                </div>
                            </div>
                            <div className="space-bottom-mobile"></div>
                        </section>
                    </> : this.props.children}
            </>
        )
    }
}

Header.defaultProps = {
    categoryId: 0,
    menuId: 0,
    tab: [],
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(Header))