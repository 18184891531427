import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callConfigPlanList } from "./../../../actions/Config/Plan";
import Helper from "./../../../components/Helper";
import Pagination from "./../../../components/Pagination";

class PlanList extends Component {

    constructor(props) {
        super(props)

        let tab = [
            {
                description: 'Consulta',
                link: '/config/plan',
                icon: 'fas fa-cog',
                active: true,
            }
        ]

        props.handlerHeader({
            tab: tab,
            categoryId: 3,
            menuId: 7
        })

        this.state = {
            pg: Helper.httpGet('pg', '1')
        }

        props.dispatch(callConfigPlanList)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.pg !== Helper.httpGet('pg', '1')) {
            nextProps.dispatch(callConfigPlanList)
            return ({
                ...prevState,
                pg: Helper.httpGet('pg', '1'),
            })
        }
        return null
    }

    render() {
        return (
            <div className="card-custom">
                <div className="card-body">
                    <form action="/" className="box-search">
                        <div className="input-search d-flex">
                            <input type="text" className="form-control rounded-0" placeholder="Pesquise pelo nome" name="search" />
                            <button type="submit" className="btn btn-primary rounded-0"><i className="fal fa-search"></i></button>
                            <button type="button" className="btn btn-secondary rounded-0"><i className="fal fa-filter"></i></button>
                        </div>
                    </form>
                    <div className="list mt-4">
                        {this.props.store.configPlan.list.rows.map((a, i) => (
                            <div className="list-item" key={'list-a-' + i}>
                                <div className="title d-flex align-items-center">
                                    <Link to={'/config/plan/edit/' + a.id} className="link-access">{a.name}</Link>
                                </div>
                                <div className="content d-flex justify-content-between">
                                    <div className="description">
                                        <strong>Preço:</strong> {a.price}<br />
                                        <strong>Qtde Itens:</strong> {a.qtyItems}<br />
                                        <strong>Cadastro:</strong> {a.createdAt}
                                    </div>
                                    <div className="options d-flex flex-column p-2">
                                        <Link to={'/config/plan/edit/' + a.id} className="btn btn-sm btn-info btn-list mb-1" title="Editar"><i className="fa fa-pencil fa-white mr-2"></i>Editar</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="clearfix mt-3 ml-3 mr-3">
                        <div className="float-left">{this.props.store.configPlan.list.total} registro(s) encontrado(s).</div>
                        <div className="float-right"><Pagination pagination={this.props.store.configPlan.list.pagination} to="/config/plan" /></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(PlanList))