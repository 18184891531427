import Helper from "./../../components/Helper"
import history from './../../routes/history'
import { toast } from 'react-toastify'

let callConfigProjectStatusList = (dispatch) => {
    let pg = Helper.httpGet('pg', '1')
    Helper.request(dispatch, 'GET', 'config/project-status?pg=' + pg, null, (data) => {
        dispatch({
            type: 'CONFIG_PROJECT_STATUS_LIST',
            payload: {
                rows: data.rows,
                pagination: data.pagination,
                total: data.total,
            }
        })
    }, null, false)
}

let callConfigProjectStatusDetail = (id) => (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/project-status/' + id, null, (data) => {
        dispatch({
            type: 'CONFIG_PROJECT_STATUS_EDIT',
            payload: {
                name: data.name,
                description: data.description,
                finish: data.finish,
                customer: data.customer,
                sort: data.sort
            }
        })
    }, null, false)
}

let callConfigProjectStatusPost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'config/project-status', data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PROJECT_STATUS_EDIT',
            payload: {
                name: '',
                description: '',
                finish: '',
                customer: '',
                sort: ''
            }
        })
        history.push('/config/project-status')
    })
}

let callConfigProjectStatusPut = (id, data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/project-status/' + id, data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PROJECT_STATUS_EDIT',
            payload: {
                name: '',
                description: '',
                finish: '',
                customer: '',
                sort: ''
            }
        })
        history.push('/config/project-status')
    })
}

let callConfigProjectStatusDelete = (id) => (dispatch) => {
    Helper.request(dispatch, 'DELETE', 'config/project-status/' + id, null, (data) => {
        toast.success(data.msg)
        dispatch(callConfigProjectStatusList);
    })
}


export { callConfigProjectStatusList, callConfigProjectStatusDetail, callConfigProjectStatusPost, callConfigProjectStatusPut, callConfigProjectStatusDelete }