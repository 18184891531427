import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callSubscriberPlan, callSubscriberRegister } from "./../../actions/Subscriber/SubscriberPlan";
import InputMask from "./../../components/InputMask";
import './../../styles/Subscriber.css'

class SubscriberRegister extends Component {
    constructor(props) {
        super(props)

        let id = props.match.params.id
        props.dispatch(callSubscriberPlan(id));

        this.state = {
            id: id,
            name: '',
            document: '',
            phone: '',
            email: '',
            pass: '',
            passConfirm: '',
        }
    }

    save() {
        this.props.dispatch(callSubscriberRegister({
            planId: this.state.id,
            name: this.state.name,
            document: this.state.document,
            phone: this.state.phone,
            email: this.state.email,
            pass: this.state.pass,
            passConfirm: this.state.passConfirm,
        }))
    }

    render() {
        return (
            <>
                <header className="b1">
                    <div className="container container-custom">
                        <div className="row">
                            <div className="col-md-12 box-info">
                                <a href="tel:+5521964116930" className="info mr-4"><i className="fas fa-mobile-alt"></i>&nbsp;&nbsp;21 96411-6930</a>
                                <a href="tel:+552135476249" className="info mr-4"><i className="fas fa-phone-alt"></i>&nbsp;&nbsp;21 3547-6249</a>
                                <a href="https://api.whatsapp.com/send?phone=5521964116930&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20" className="info mr-4"><i className="fab fa-whatsapp"></i>&nbsp;&nbsp;Chamar no WhatsApp</a>
                            </div>
                        </div>
                    </div>
                </header>
                <header className="b2">
                    <div className="container container-custom">
                        <div className="row">
                            <div className="col-md-12 box-logo">
                                <a href="/" className="logo"><img src={this.props.store.config.logoB} alt="" className="img-fluid" /></a>
                            </div>
                        </div>
                    </div>
                </header>
                <header className="b3">
                    <div className="container container-custom">
                        <div className="row">
                            <div className="col-md-12 box-description">Garantimos a aprovação do projeto ou o valor investido devolvido</div>
                        </div>
                    </div>
                </header>
                <div className="container container-custom mt-5">
                    <h2 className="title-main text-center">Informe seus dados pessoais e escolha a forma de pagamento.</h2>
                    <div className="row plan mt-5">
                        <div className="col-md-8">
                            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                                <div className="form-row">
                                    <div className="col-md-6 form-group">
                                        <label className={'required'}>Nome Completo</label>
                                        <input type="text" className="form-control" onChange={(e) => { this.setState({ name: e.target.value }) }} value={this.state.name} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label className={'required'}>CPF</label>
                                        <InputMask mask="cpf" className="form-control" value={this.state.document} onChange={(v) => { this.setState({ document: v }) }} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label className={'required'}>Telefone</label>
                                        <InputMask mask="phone" className="form-control" value={this.state.phone} onChange={(v) => { this.setState({ phone: v }) }} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label className="required">E-mail</label>
                                        <input type="text" className="form-control" onChange={(e) => { this.setState({ email: e.target.value }) }} value={this.state.email} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label className="required">Senha</label>
                                        <input type="password" className="form-control" onChange={(e) => { this.setState({ pass: e.target.value }) }} value={this.state.pass} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label className="required">Confirmaçao de Senha</label>
                                        <input type="password" className="form-control" onChange={(e) => { this.setState({ passConfirm: e.target.value }) }} value={this.state.passConfirm} />
                                    </div>
                                    <div className="col-md-6 text-left">
                                        <Link to={'/subscriber'} className="btn btn-secondary btn-lg">Escolher outro plano</Link>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <button type="submit" className="btn btn-primary btn-lg">Finalizar e processar pagamento</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-3">
                            <div className="plan-item">
                                <div className="h" style={{ backgroundColor: this.props.store.subscriberPlan.plan.background }}>
                                    <div className="title" style={{ color: this.props.store.subscriberPlan.plan.color }}>{this.props.store.subscriberPlan.plan.name}</div>
                                    <div className="qty" style={{ color: this.props.store.subscriberPlan.plan.color }}>{this.props.store.subscriberPlan.plan.qtyItems}</div>
                                </div>
                                <div className="b">
                                    <div className="installment">{this.props.store.subscriberPlan.plan.installment}<span className="cent">{this.props.store.subscriberPlan.plan.cent}</span></div>
                                    <div className="options">
                                        {this.props.store.subscriberPlan.plan.options.map((b, bi) => (
                                            <div className="options-item" key={'option-' + bi}><i className={b.check ? 'fas fa-check' : 'fas fa-times'}></i> {b.description}</div>
                                        ))}
                                    </div>
                                    <div className="actions">
                                        <Link to={'/subscriber'} type="button" className="enter">Escolher outro plano</Link>
                                    </div>
                                    <div className="price">{this.props.store.subscriberPlan.plan.price}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(SubscriberRegister))