import Helper from "./../../components/Helper"
import { toast } from 'react-toastify'

let callConfigGeneralDetail = (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/general', null, (data) => {
        dispatch({
            type: 'CONFIG_GENERAL',
            payload: {
                c1: data.c1,
                c2: data.c2,
                c3: data.c3,
                c4: data.c4,
                c5: data.c5,
                c6: data.c6,
                c7: data.c7,
                c8: data.c8,
                c9: data.c9,
                d1: data.d1,
                d2: data.d2,
                d3: data.d3,
                d4: data.d4,
                d5: data.d5,
                d6: data.d6,
                d7: data.d7,
                d8: data.d8,
                d9: data.d9,
            }
        })
    }, null, false)
}

let callConfigGeneralPut = (data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/general', data, (data) => {
        toast.success(data.msg)
    })
}


export { callConfigGeneralDetail, callConfigGeneralPut }