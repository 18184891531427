import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

const initialState = {
    first: true,
    loader: false,
    error: '',
    success: '',
    config: {
        logoA: '',
        logoB: '',
        logoEloss: '',
        bgLoginA: '',
        ip: '',
    },
    session: {
        logged: false,
        name: '',
        photo: '',
        profileId: null,
    },
    headerIn: {
        categories: [],
    },
    customer: {
        list: {
            rows: [],
            pagination: [],
            total: 0,
        },
        detail: {
            name: '',
            email: '',
        }
    },
    admin: {
        list: {
            rows: [],
            pagination: [],
            total: 0,
        },
        detail: {
            name: '',
            email: '',
        }
    },
    collaborator: {
        list: {
            rows: [],
            pagination: [],
            total: 0,
        },
        detail: {
            name: '',
            email: '',
        }
    },
    configGeneral: {
        detail: {
            c1: '',
            c2: '',
            c3: '',
            c4: '',
            c5: '',
            c6: '',
            c7: '',
            c8: '',
            c9: '',
            d1: '',
            d2: '',
            d3: '',
            d4: '',
            d5: '',
            d6: '',
            d7: '',
            d8: '',
            d9: '',
        }
    },
    configFree: {
        detail: {
            c10: '',
            c11: '',
            c12: '',
            c13: '',
            c14: '',
            c15: '',
            c16: '',
            c17: '',
            c18: '',
            c19: '',
            c20: '',
            c21: '',
            c22: '',
            d10: '',
            d11: '',
            d12: '',
            d13: '',
            d14: '',
            d15: '',
            d16: '',
            d17: '',
            d18: '',
            d19: '',
            d20: '',
            d21: '',
            d22: '',
        }
    },
    configPlan: {
        list: {
            rows: [],
            pagination: [],
            total: 0,
        },
        detail: {
            name: '',
            description: '',
            price: '',
            sort: '',
            qtyItems: '',
        }
    },
    configBank: {
        list: {
            rows: [],
            pagination: [],
            total: 0,
        },
        detail: {
            name: '',
        }
    },
    configProjectType: {
        list: {
            rows: [],
            pagination: [],
            total: 0,
        },
        detail: {
            name: '',
            description: '',
            percCapture: '',
            servicePrice: '',
            maxPercActivity: '',
            active: '',
            timetable: ''
        }
    },
    configProjectStatus: {
        list: {
            rows: [],
            pagination: [],
            total: 0,
        },
        detail: {
            name: '',
            description: '',
            finish: '',
            customer: '',
            sort: ''
        }
    },
    configProjectSport: {
        list: {
            rows: [],
            pagination: [],
            total: 0,
        },
        detail: {
            name: '',
            description: '',
            typesSelected: [],
            active: '',
            types: [],
        }
    },
    configProjectSportCategory: {
        list: {
            rows: [],
            pagination: [],
            total: 0,
        },
        detail: {
            name: '',
        }
    },
    configProjectSportOffice: {
        list: {
            rows: [],
            pagination: [],
            total: 0,
        },
        detail: {
            name: '',
            description: '',
            formation: '',
            assignment: '',
            activity: '',
            sports: [],
            active: true,
        }
    },
    subscriberPlan: {
        plans: [],
        plan: {
            id: '',
            name: '',
            description: '',
            price: '',
            qtyItems: '',
            background: '',
            color: '',
            installment: '',
            cent: '',
            options: [],
        }
    },
    configPayment: {
        publicKey: '',
        accessToken: '',
        production: '',
    },
    configLead: {
        html: '',
    },
    dashboard: {
        orders: [],
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FIRST':
            return {
                ...state,
                first: action.payload.first,
            }
        case 'LOADER':
            return {
                ...state,
                loader: action.payload.loader,
            }
        case 'ERROR':
            return {
                ...state,
                error: action.payload.error,
            }
        case 'SUCCESS':
            return {
                ...state,
                success: action.payload.success,
            }
        case 'SESSION':
            return {
                ...state,
                session: {
                    logged: action.payload.logged,
                    name: action.payload.name,
                    photo: action.payload.photo,
                    profileId: action.payload.profileId
                }
            }
        case 'MENU':
            return {
                ...state,
                headerIn: {
                    categories: action.payload.categories,
                }
            }
        case 'CONFIG':
            return {
                ...state,
                first: false,
                config: {
                    logoA: action.payload.logoA,
                    logoB: action.payload.logoB,
                    logoEloss: action.payload.logoEloss,
                    bgLoginA: action.payload.bgLoginA,
                    ip: action.payload.ip,
                }
            }
        case 'CUSTOMER_LIST':
            return {
                ...state,
                customer: {
                    list: {
                        rows: action.payload.rows,
                        pagination: action.payload.pagination,
                        total: action.payload.total,
                    },
                    detail: state.customer.detail,
                }
            }
        case 'CUSTOMER_EDIT':
            return {
                ...state,
                customer: {
                    list: state.customer.list,
                    detail: {
                        name: action.payload.name,
                        email: action.payload.email,
                    },
                }
            }
        case 'ADMIN_LIST':
            return {
                ...state,
                admin: {
                    list: {
                        rows: action.payload.rows,
                        pagination: action.payload.pagination,
                        total: action.payload.total,
                    },
                    detail: state.admin.detail,
                }
            }
        case 'ADMIN_EDIT':
            return {
                ...state,
                admin: {
                    list: state.admin.list,
                    detail: {
                        name: action.payload.name,
                        email: action.payload.email,
                    },
                }
            }
        case 'COLLABORATOR_LIST':
            return {
                ...state,
                collaborator: {
                    list: {
                        rows: action.payload.rows,
                        pagination: action.payload.pagination,
                        total: action.payload.total,
                    },
                    detail: state.collaborator.detail,
                }
            }
        case 'COLLABORATOR_EDIT':
            return {
                ...state,
                collaborator: {
                    list: state.collaborator.list,
                    detail: {
                        name: action.payload.name,
                        email: action.payload.email,
                    },
                }
            }
        case 'CONFIG_GENERAL':
            return {
                ...state,
                configGeneral: {
                    detail: {
                        c1: action.payload.c1,
                        c2: action.payload.c2,
                        c3: action.payload.c3,
                        c4: action.payload.c4,
                        c5: action.payload.c5,
                        c6: action.payload.c6,
                        c7: action.payload.c7,
                        c8: action.payload.c8,
                        c9: action.payload.c9,
                        d1: action.payload.d1,
                        d2: action.payload.d2,
                        d3: action.payload.d3,
                        d4: action.payload.d4,
                        d5: action.payload.d5,
                        d6: action.payload.d6,
                        d7: action.payload.d7,
                        d8: action.payload.d8,
                        d9: action.payload.d9,
                    },
                }
            }
        case 'CONFIG_FREE':
            return {
                ...state,
                configFree: {
                    detail: {
                        c10: action.payload.c10,
                        c11: action.payload.c11,
                        c12: action.payload.c12,
                        c13: action.payload.c13,
                        c14: action.payload.c14,
                        c15: action.payload.c15,
                        c16: action.payload.c16,
                        c17: action.payload.c17,
                        c18: action.payload.c18,
                        c19: action.payload.c19,
                        c20: action.payload.c20,
                        c21: action.payload.c21,
                        c22: action.payload.c22,
                        d10: action.payload.d10,
                        d11: action.payload.d11,
                        d12: action.payload.d12,
                        d13: action.payload.d13,
                        d14: action.payload.d14,
                        d15: action.payload.d15,
                        d16: action.payload.d16,
                        d17: action.payload.d17,
                        d18: action.payload.d18,
                        d19: action.payload.d19,
                        d20: action.payload.d20,
                        d21: action.payload.d21,
                        d22: action.payload.d22,
                    },
                }
            }
        case 'CONFIG_PLAN_LIST':
            return {
                ...state,
                configPlan: {
                    list: {
                        rows: action.payload.rows,
                        pagination: action.payload.pagination,
                        total: action.payload.total,
                    },
                    detail: state.configPlan.detail,
                }
            }
        case 'CONFIG_PLAN_EDIT':
            return {
                ...state,
                configPlan: {
                    list: state.configPlan.list,
                    detail: {
                        name: action.payload.name,
                        description: action.payload.description,
                        price: action.payload.price,
                        sort: action.payload.sort,
                        qtyItems: action.payload.qtyItems,
                    },
                }
            }
        case 'CONFIG_BANK_LIST':
            return {
                ...state,
                configBank: {
                    list: {
                        rows: action.payload.rows,
                        pagination: action.payload.pagination,
                        total: action.payload.total,
                    },
                    detail: state.configBank.detail,
                }
            }
        case 'CONFIG_BANK_EDIT':
            return {
                ...state,
                configBank: {
                    list: state.configBank.list,
                    detail: {
                        name: action.payload.name,
                    },
                }
            }
        case 'CONFIG_PROJECT_TYPE_LIST':
            return {
                ...state,
                configProjectType: {
                    list: {
                        rows: action.payload.rows,
                        pagination: action.payload.pagination,
                        total: action.payload.total,
                    },
                    detail: state.configProjectType.detail,
                }
            }
        case 'CONFIG_PROJECT_TYPE_EDIT':
            return {
                ...state,
                configProjectType: {
                    list: state.configProjectType.list,
                    detail: {
                        name: action.payload.name,
                        description: action.payload.description,
                        percCapture: action.payload.percCapture,
                        servicePrice: action.payload.servicePrice,
                        maxPercActivity: action.payload.maxPercActivity,
                        active: action.payload.active,
                        timetable: action.payload.timetable
                    },
                }
            }
        case 'CONFIG_PROJECT_STATUS_LIST':
            return {
                ...state,
                configProjectStatus: {
                    list: {
                        rows: action.payload.rows,
                        pagination: action.payload.pagination,
                        total: action.payload.total,
                    },
                    detail: state.configProjectStatus.detail,
                }
            }
        case 'CONFIG_PROJECT_STATUS_EDIT':
            return {
                ...state,
                configProjectStatus: {
                    list: state.configProjectStatus.list,
                    detail: {
                        name: action.payload.name,
                        description: action.payload.description,
                        finish: action.payload.finish,
                        customer: action.payload.customer,
                        sort: action.payload.sort
                    },
                }
            }
        case 'CONFIG_PROJECT_SPORT_LIST':
            return {
                ...state,
                configProjectSport: {
                    list: {
                        rows: action.payload.rows,
                        pagination: action.payload.pagination,
                        total: action.payload.total,
                    },
                    detail: state.configProjectSport.detail,
                }
            }
        case 'CONFIG_PROJECT_SPORT_EDIT':
            return {
                ...state,
                configProjectSport: {
                    list: state.configProjectSport.list,
                    detail: {
                        name: action.payload.name,
                        description: action.payload.description,
                        typesSelected: action.payload.typesSelected,
                        active: action.payload.active,
                        types: action.payload.types,
                    },
                }
            }
        case 'CONFIG_PROJECT_SPORT_CATEGORY_LIST':
            return {
                ...state,
                configProjectSportCategory: {
                    list: {
                        rows: action.payload.rows,
                        pagination: action.payload.pagination,
                        total: action.payload.total,
                    },
                    detail: state.configProjectSportCategory.detail,
                }
            }
        case 'CONFIG_PROJECT_SPORT_CATEGORY_EDIT':
            return {
                ...state,
                configProjectSportCategory: {
                    list: state.configProjectSportCategory.list,
                    detail: {
                        name: action.payload.name,
                    },
                }
            }
        case 'CONFIG_PROJECT_SPORT_OFFICE_LIST':
            return {
                ...state,
                configProjectSportOffice: {
                    list: {
                        rows: action.payload.rows,
                        pagination: action.payload.pagination,
                        total: action.payload.total,
                    },
                    detail: state.configProjectSportOffice.detail,
                }
            }
        case 'CONFIG_PROJECT_SPORT_OFFICE_EDIT':
            return {
                ...state,
                configProjectSportOffice: {
                    list: state.configProjectSportOffice.list,
                    detail: {
                        name: action.payload.name,
                        description: action.payload.description,
                        formation: action.payload.formation,
                        assignment: action.payload.assignment,
                        activity: action.payload.activity,
                        sports: action.payload.sports,
                        active: action.payload.active,
                    },
                }
            }
        case 'SUBSCRIBER_GET':
            return {
                ...state,
                subscriberPlan: {
                    plans: action.payload.plans,
                    plan: state.subscriberPlan.plan
                }
            }
        case 'SUBSCRIBER_PLAN':
            return {
                ...state,
                subscriberPlan: {
                    plans: state.subscriberPlan.plans,
                    plan: action.payload.plan
                }
            }
        case 'CONFIG_PAYMENT':
            return {
                ...state,
                configPayment: {
                    publicKey: action.payload.publicKey,
                    accessToken: action.payload.accessToken,
                    production: action.payload.production,
                }
            }
        case 'CONFIG_LEAD':
            return {
                ...state,
                configLead: {
                    html: action.payload.html,
                }
            }
        case 'DASHBOARD':
            return {
                ...state,
                dashboard: {
                    orders: action.payload.orders,
                }
            }
        default:
            break;
    }
    return state
}

const store = createStore(reducer, composedEnhancer)

export default store