import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callConfigProjectTypeDetail, callConfigProjectTypePost, callConfigProjectTypePut } from "./../../../actions/Config/ProjectType";
import InputMask from "./../../../components/InputMask";

class ConfigProjectTypeEdit extends Component {
    constructor(props) {
        super(props)

        let id = 0
        let add = true
        if (typeof (props.match.params.id) != 'undefined') {
            id = props.match.params.id
            add = false
        }

        let tab = [
            {
                description: 'Consulta',
                link: '/config/project-type',
                icon: 'fas fa-cog',
                active: false,
            },
        ]

        if (add) {
            tab.push({
                description: 'Adicionar',
                link: '/config/project-type/add',
                icon: 'fas fa-plus',
                active: true,
            })
        } else {
            tab.push({
                description: 'Editar',
                link: '/config/project-type/edit/' + id,
                icon: 'fas fa-save',
                active: true,
            })
        }

        props.handlerHeader({
            tab: tab,
            categoryId: 5,
            menuId: 9
        })

        props.dispatch(callConfigProjectTypeDetail(id))

        this.state = {
            add: add,
            load: true,
            id: id,
            name: '',
            description: '',
            percCapture: '',
            servicePrice: '',
            maxPercActivity: '',
            active: '',
            timetable: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.add && prevState.load && nextProps.store.configProjectType.detail.name) {
            return ({
                ...prevState,
                name: nextProps.store.configProjectType.detail.name,
                description: nextProps.store.configProjectType.detail.description,
                percCapture: nextProps.store.configProjectType.detail.percCapture,
                servicePrice: nextProps.store.configProjectType.detail.servicePrice,
                maxPercActivity: nextProps.store.configProjectType.detail.maxPercActivity,
                active: nextProps.store.configProjectType.detail.active,
                timetable: nextProps.store.configProjectType.detail.timetable,
                load: false,
            })
        }

        if (prevState.add && prevState.load) {
            return ({
                ...prevState,
                load: false,
            })
        }

        return null
    }

    save() {
        if (this.state.add) {
            this.props.dispatch(callConfigProjectTypePost({
                name: this.state.name,
                description: this.state.description,
                percCapture: this.state.percCapture,
                servicePrice: this.state.servicePrice,
                maxPercActivity: this.state.maxPercActivity,
                active: this.state.active,
                timetable: this.state.timetable

            }));
        } else {
            this.props.dispatch(callConfigProjectTypePut(this.state.id, {
                name: this.state.name,
                description: this.state.description,
                percCapture: this.state.percCapture,
                servicePrice: this.state.servicePrice,
                maxPercActivity: this.state.maxPercActivity,
                active: this.state.active,
                timetable: this.state.timetable
            }));
        }
    }

    render() {
        return (
            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                <div className="card-custom">
                    <div className="card-body">
                        <h1 className="title-form">INFORMAÇÕES PRINCIPAIS</h1>
                        <div className="form-row">
                            <div className="col-md-4 form-group">
                                <label className="required">Nome</label>
                                <input type="text" className="form-control" onChange={(e) => { this.setState({ name: e.target.value }) }} value={this.state.name} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label className="required">Descrição</label>
                                <textarea className="form-control" onChange={(e) => { this.setState({ description: e.target.value }) }} value={this.state.description}></textarea>
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="required">Percentual de valor de Captação</label>
                                <InputMask mask="dec_3" value={this.state.percCapture} className="form-control text-right" onChange={(v) => { this.setState({ percCapture: v }) }} />
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="required">Valor do Serviço Especializado</label>
                                <InputMask mask="dec_2" value={this.state.servicePrice} className="form-control text-right" onChange={(v) => { this.setState({ servicePrice: v }) }} />
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="required">Percentual Maximo das Atividades meio</label>
                                <InputMask mask="dec_3" value={this.state.maxPercActivity} className="form-control text-right" onChange={(v) => { this.setState({ maxPercActivity: v }) }} />
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="required">Ativo</label>
                                <select className="form-control" value={this.state.active} onChange={(e) => { this.setState({ active: e.target.value }) }}>
                                    <option value="">Selecione</option>
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="required">Requer Grade Horária</label>
                                <select className="form-control" value={this.state.timetable} onChange={(e) => { this.setState({ timetable: e.target.value }) }}>
                                    <option value="">Selecione</option>
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="card-body form-btn">
                        <button className="btn btn-primary" type="submit"><i className="fas fa-save mr-1"></i>Salvar</button>
                        <Link to={'/config/project-type'} className="btn btn-secondary ml-1"><i className="mr-1 fas fa-ban"></i>Cancelar</Link>
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ConfigProjectTypeEdit))