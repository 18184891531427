import Helper from "./../../components/Helper"
import { toast } from 'react-toastify'

let callConfigLead = (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/lead', null, (data) => {
        dispatch({
            type: 'CONFIG_LEAD',
            payload: {
                html: data.html,
            }
        })
    }, null, false)
}

let callConfigLeadPut = (data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/lead', data, (data) => {
        toast.success(data.msg)
    })
}


export { callConfigLead, callConfigLeadPut }