import Helper from "./../../components/Helper"
import history from './../../routes/history'
import { toast } from 'react-toastify'

let callConfigPlanList = (dispatch) => {
    let pg = Helper.httpGet('pg', '1')
    Helper.request(dispatch, 'GET', 'config/plan?pg=' + pg, null, (data) => {
        dispatch({
            type: 'CONFIG_PLAN_LIST',
            payload: {
                rows: data.rows,
                pagination: data.pagination,
                total: data.total,
            }
        })
    }, null, false)
}

let callConfigPlanDetail = (id) => (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/plan/' + id, null, (data) => {
        dispatch({
            type: 'CONFIG_PLAN_EDIT',
            payload: {
                name: data.name,
                description: data.description,
                price: data.price,
                sort: data.sort,
                qtyItems: data.qtyItems,
            }
        })
    }, null, false)
}

let callConfigPlanPut = (id, data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/plan/' + id, data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PLAN_EDIT',
            payload: {
                name: '',
                description: '',
                price: '',
                sort: '',
                qtyItems: '',
            }
        })
        history.push('/config/plan')
    })
}


export { callConfigPlanList, callConfigPlanDetail, callConfigPlanPut }