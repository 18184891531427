import Helper from "./../../components/Helper"
import { toast } from 'react-toastify'

let callConfigPayment = (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/payment', null, (data) => {
        dispatch({
            type: 'CONFIG_PAYMENT',
            payload: {
                publicKey: data.publicKey,
                accessToken: data.accessToken,
                production: data.production,
            }
        })
    }, null, false)
}

let callConfigPaymentPut = (data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/payment', data, (data) => {
        toast.success(data.msg)
    })
}


export { callConfigPayment, callConfigPaymentPut }