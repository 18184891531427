import Helper from "./../components/Helper"
import history from './../routes/history'
import { callConfigMenuGet } from "./App"

let callSupportLoginPost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'support/login', data, (data) => {
        history.push('/support/code')
    })
}

let callSupportCodePost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'support/code', data, (data) => {
        dispatch({
            type: 'SESSION',
            payload: {
                name: data.name,
                logged: data.logged,
                photo: data.photo,
                profileId: data.profileId,
            }
        })
        document.body.className = document.getElementById('root').className = 'body-in'
        dispatch(callConfigMenuGet)
        history.push('/dashboard')
    })
}

export { callSupportLoginPost, callSupportCodePost }