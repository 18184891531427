import Helper from './../components/Helper'
import { callConfigGet, callConfigMenuGet } from './App'
import history from './../routes/history'

let callAuthGet = (dispatch, getState) => {
    Helper.request(dispatch, 'GET', 'auth', null, (data) => {
        dispatch({
            type: 'SESSION',
            payload: {
                name: data.name,
                logged: data.logged,
                photo: data.photo,
                profileId: data.profileId,
            }
        })

        if (getState().first) {
            dispatch(callConfigGet)
        }

        if (data.logged) {
            document.body.className = document.getElementById('root').className = 'body-in'
            dispatch(callConfigMenuGet)
            return;
        }

        document.body.className = document.getElementById('root').className = 'body-out'
    }, null, false)
}

let callAuthPost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'auth', data, (data) => {
        dispatch({
            type: 'SESSION',
            payload: {
                name: data.name,
                logged: data.logged,
                photo: data.photo,
                profileId: data.profileId,
            }
        })
        document.body.className = document.getElementById('root').className = 'body-in'
        dispatch(callConfigMenuGet)
        history.push('/dashboard')
    })
}

let callAuthDelete = (dispatch) => {
    Helper.request(dispatch, 'DELETE', 'auth', null, () => {
        dispatch({
            type: 'SESSION',
            payload: {
                name: '',
                logged: false,
                photo: '',
                profileId: null,
            }
        })
        history.push('/account/login')
    })
}

export { callAuthGet, callAuthDelete, callAuthPost }