import Helper from "./../../components/Helper"
import history from './../../routes/history'
import { toast } from 'react-toastify'

let callConfigBankList = (dispatch) => {
    let pg = Helper.httpGet('pg', '1')
    Helper.request(dispatch, 'GET', 'config/bank?pg=' + pg, null, (data) => {
        dispatch({
            type: 'CONFIG_BANK_LIST',
            payload: {
                rows: data.rows,
                pagination: data.pagination,
                total: data.total,
            }
        })
    }, null, false)
}

let callConfigBankDetail = (id) => (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/bank/' + id, null, (data) => {
        dispatch({
            type: 'CONFIG_BANK_EDIT',
            payload: {
                name: data.name,
            }
        })
    }, null, false)
}

let callConfigBankPost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'config/bank', data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_BANK_EDIT',
            payload: {
                name: '',
            }
        })
        history.push('/config/bank')
    })
}

let callConfigBankPut = (id, data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/bank/' + id, data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_BANK_EDIT',
            payload: {
                name: '',
            }
        })
        history.push('/config/bank')
    })
}

let callConfigBankDelete = (id) => (dispatch) => {
    Helper.request(dispatch, 'DELETE', 'config/bank/' + id, null, (data) => {
        toast.success(data.msg)
        dispatch(callConfigBankList);
    })
}


export { callConfigBankList, callConfigBankDetail, callConfigBankPost, callConfigBankPut, callConfigBankDelete }