import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callConfigPlanDetail, callConfigPlanPut } from "./../../../actions/Config/Plan";
import InputMask from "./../../../components/InputMask";

class PlanEdit extends Component {
    constructor(props) {
        super(props)

        let id = props.match.params.id

        let tab = [
            {
                description: 'Consulta',
                link: '/config/plan',
                icon: 'fas fa-cog',
                active: false,
            },
        ]

        tab.push({
            description: 'Editar',
            link: '/config/plan/edit/' + id,
            icon: 'fas fa-save',
            active: true,
        })

        props.handlerHeader({
            tab: tab,
            categoryId: 3,
            menuId: 7
        })

        props.dispatch(callConfigPlanDetail(id))

        this.state = {
            load: true,
            id: id,
            name: '',
            description: '',
            price: '',
            sort: '',
            qtyItems: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.load && nextProps.store.configPlan.detail.name) {
            return ({
                ...prevState,
                name: nextProps.store.configPlan.detail.name,
                description: nextProps.store.configPlan.detail.description,
                price: nextProps.store.configPlan.detail.price,
                sort: nextProps.store.configPlan.detail.sort,
                qtyItems: nextProps.store.configPlan.detail.qtyItems,
                load: false,
            })
        }

        return null
    }

    save() {
        this.props.dispatch(callConfigPlanPut(this.state.id, {
            name: this.state.name,
            description: this.state.description,
            price: this.state.price,
            sort: this.state.sort,
            qtyItems: this.state.qtyItems,
        }));
    }

    render() {
        return (
            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                <div className="card-custom">
                    <div className="card-body">
                        <h1 className="title-form">INFORMAÇÕES PRINCIPAIS</h1>
                        <div className="form-row">
                            <div class="col-md-6 form-group">
                                <div class="form-row">
                                    <div className="col-md-12 form-group">
                                        <label className={'required'}>Nome</label>
                                        <input type="text" className="form-control" onChange={(e) => { this.setState({ name: e.target.value }) }} value={this.state.name} />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label className={'required'}>Preço</label>
                                        <InputMask mask="dec_2" className="form-control text-right" value={this.state.price} onChange={(v) => { this.setState({ price: v }) }} />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label className={'required'}>Qtde de Itens</label>
                                        <InputMask mask="num" className="form-control text-right" value={this.state.qtyItems} onChange={(v) => { this.setState({ qtyItems: v }) }} />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label className={'required'}>Ordem</label>
                                        <InputMask mask="num" className="form-control text-right" value={this.state.sort} onChange={(v) => { this.setState({ sort: v }) }} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <div class="form-row">
                                    <div className="col-md-12 form-group">
                                        <label className={'required'}>Benefícios</label>
                                        <textarea className="form-control" rows={10} onChange={(e) => { this.setState({ description: e.target.value }) }} value={this.state.description}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body form-btn">
                        <button className="btn btn-primary" type="submit"><i className="fas fa-save mr-1"></i>Salvar</button>
                        <Link to={'/config/plan'} className="btn btn-secondary ml-1"><i className="mr-1 fas fa-ban"></i>Cancelar</Link>
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(PlanEdit))