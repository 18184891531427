import Helper from "./../../components/Helper"
import history from './../../routes/history'
import { toast } from 'react-toastify'

let callRegisterCollaboratorList = (dispatch) => {
    let pg = Helper.httpGet('pg', '1')
    Helper.request(dispatch, 'GET', 'register/collaborator?pg=' + pg, null, (data) => {
        dispatch({
            type: 'COLLABORATOR_LIST',
            payload: {
                rows: data.rows,
                pagination: data.pagination,
                total: data.total,
            }
        })
    }, null, false)
}

let callRegisterCollaboratorDetail = (id) => (dispatch) => {
    Helper.request(dispatch, 'GET', 'register/collaborator/' + id, null, (data) => {
        dispatch({
            type: 'COLLABORATOR_EDIT',
            payload: {
                name: data.name,
                email: data.email,
            }
        })
    }, null, false)
}

let callRegisterCollaboratorPost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'register/collaborator', data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'COLLABORATOR_EDIT',
            payload: {
                name: '',
                email: '',
            }
        })
        history.push('/register/collaborator')
    })
}

let callRegisterCollaboratorPut = (id, data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'register/collaborator/' + id, data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'COLLABORATOR_EDIT',
            payload: {
                name: '',
                email: '',
            }
        })
        history.push('/register/collaborator')
    })
}

let callRegisterCollaboratorDelete = (id) => (dispatch) => {
    Helper.request(dispatch, 'DELETE', 'register/collaborator/' + id, null, (data) => {
        toast.success(data.msg)
        dispatch(callRegisterCollaboratorList);
    })
}


export { callRegisterCollaboratorList, callRegisterCollaboratorDetail, callRegisterCollaboratorPost, callRegisterCollaboratorPut, callRegisterCollaboratorDelete }