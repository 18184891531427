import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { callConfigPayment, callConfigPaymentPut } from "./../../../actions/Config/Payment";

class ConfigPayment extends Component {
    constructor(props) {
        super(props)

        let tab = [
            {
                description: 'Editar',
                link: '/config/payment',
                icon: 'fas fa-save',
                active: false,
            },
        ]

        props.handlerHeader({
            tab: tab,
            categoryId: 3,
            menuId: 14
        })

        props.dispatch(callConfigPayment)

        this.state = {
            load: true,
            publicKey: '',
            accessToken: '',
            production: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.load && nextProps.store.configPayment.production) {
            return ({
                ...prevState,
                publicKey: nextProps.store.configPayment.publicKey,
                accessToken: nextProps.store.configPayment.accessToken,
                production: nextProps.store.configPayment.production,
                load: false,
            })
        }

        return null
    }

    save() {
        this.props.dispatch(callConfigPaymentPut({
            publicKey: this.state.publicKey,
            accessToken: this.state.accessToken,
            production: this.state.production,
        }));
    }

    render() {
        return (
            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                <div className="card-custom">
                    <div className="card-body">
                        <h1 className="title-form">INFORMAÇÕES PRINCIPAIS</h1>
                        <div className="form-row">
                            <div className="col-md-4 form-group">
                                <label className="required">Ambiente de Produção?</label>
                                <select className="form-control" value={this.state.production} onChange={(e) => { this.setState({ production: e.target.value }) }}>
                                    <option value="">Selecione</option>
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>
                            <div className="col-md-4 form-group">
                                <label>PUBLIC KEY</label>
                                <input type="text" className="form-control" value={this.state.publicKey} onChange={(e) => { this.setState({ publicKey: e.target.value }) }} />
                            </div>
                            <div className="col-md-4 form-group">
                                <label>ACCESS TOKEN</label>
                                <input type="text" className="form-control" value={this.state.accessToken} onChange={(e) => { this.setState({ accessToken: e.target.value }) }} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body form-btn">
                        <button className="btn btn-primary" type="submit"><i className="fas fa-save mr-1"></i>Alterar</button>
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ConfigPayment))