import { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { callConfigGeneralDetail, callConfigGeneralPut } from "./../../../actions/Config/General";
import { Editor } from '@tinymce/tinymce-react';
import InputMask from "./../../../components/InputMask";
import { EDITOR_KEY } from "./../../../constants/Config";

class ConfigGeneral extends Component {
    constructor(props) {
        super(props)

        let tab = [
            {
                description: 'Editar',
                link: '/config/general',
                icon: 'fas fa-save',
                active: false,
            },
        ]

        props.handlerHeader({
            tab: tab,
            categoryId: 3,
            menuId: 5
        })

        props.dispatch(callConfigGeneralDetail)

        this.editor5 = createRef()
        this.editor6 = createRef()
        this.editor7 = createRef()
        this.editor8 = createRef()

        this.state = {
            load: true,
            c1: '',
            c2: '',
            c3: '',
            c4: '',
            c5: '',
            c6: '',
            c7: '',
            c8: '',
            c9: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.load && nextProps.store.configGeneral.detail.c1) {
            return ({
                ...prevState,
                c1: nextProps.store.configGeneral.detail.c1,
                c2: nextProps.store.configGeneral.detail.c2,
                c3: nextProps.store.configGeneral.detail.c3,
                c4: nextProps.store.configGeneral.detail.c4,
                c5: nextProps.store.configGeneral.detail.c5,
                c6: nextProps.store.configGeneral.detail.c6,
                c7: nextProps.store.configGeneral.detail.c7,
                c8: nextProps.store.configGeneral.detail.c8,
                c9: nextProps.store.configGeneral.detail.c9,
                load: false,
            })
        }

        return null
    }

    save() {
        this.props.dispatch(callConfigGeneralPut({
            c1: this.state.c1,
            c2: this.state.c2,
            c3: this.state.c3,
            c4: this.state.c4,
            c5: this.state.c5,
            c6: this.state.c6,
            c7: this.state.c7,
            c8: this.state.c8,
            c9: this.state.c9,
        }));
    }

    render() {
        return (
            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                <div className="card-custom">
                    <div className="card-body">
                        <h1 className="title-form">INFORMAÇÕES PRINCIPAIS</h1>
                        <div className="form-row">
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configGeneral.detail.d1}</label>
                                <InputMask mask="dec_3" value={this.state.c1} onChange={(v) => { this.setState({ c1: v }) }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configGeneral.detail.d2}</label>
                                <InputMask mask="dec_3" value={this.state.c2} onChange={(v) => { this.setState({ c2: v }) }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configGeneral.detail.d3}</label>
                                <InputMask mask="num" value={this.state.c3} onChange={(v) => { this.setState({ c3: v }) }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configGeneral.detail.d4}</label>
                                <InputMask mask="num" value={this.state.c4} onChange={(v) => { this.setState({ c4: v }) }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configGeneral.detail.d5}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor5.current = editor}
                                    initialValue={this.props.store.configGeneral.detail.c5}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configGeneral.detail.d6}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor6.current = editor}
                                    initialValue={this.props.store.configGeneral.detail.c6}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configGeneral.detail.d7}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor7.current = editor}
                                    initialValue={this.props.store.configGeneral.detail.c7}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configGeneral.detail.d8}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor8.current = editor}
                                    initialValue={this.props.store.configGeneral.detail.c8}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configGeneral.detail.d9}</label>
                                <input type="text" className="form-control" defaultValue={this.props.store.configGeneral.detail.c9} onChange={(e) => { this.setState({ c9: e.target.value }) }} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body form-btn">
                        <button className="btn btn-primary" type="submit"><i className="fas fa-save mr-1"></i>Alterar</button>
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ConfigGeneral))