import Helper from "./../../components/Helper"
import history from './../../routes/history'
import { toast } from 'react-toastify'

let callConfigProjectTypeList = (dispatch) => {
    let pg = Helper.httpGet('pg', '1')
    Helper.request(dispatch, 'GET', 'config/project-type?pg=' + pg, null, (data) => {
        dispatch({
            type: 'CONFIG_PROJECT_TYPE_LIST',
            payload: {
                rows: data.rows,
                pagination: data.pagination,
                total: data.total,
            }
        })
    }, null, false)
}

let callConfigProjectTypeDetail = (id) => (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/project-type/' + id, null, (data) => {
        dispatch({
            type: 'CONFIG_PROJECT_TYPE_EDIT',
            payload: {
                name: data.name,
                description: data.description,
                percCapture: data.percCapture,
                servicePrice: data.servicePrice,
                maxPercActivity: data.maxPercActivity,
                active: data.active,
                timetable: data.timetable
            }
        })
    }, null, false)
}

let callConfigProjectTypePost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'config/project-type', data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PROJECT_TYPE_EDIT',
            payload: {
                name: '',
                description: '',
                percCapture: '',
                servicePrice: '',
                maxPercActivity: '',
                active: '',
                timetable: ''
            }
        })
        history.push('/config/project-type')
    })
}

let callConfigProjectTypePut = (id, data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/project-type/' + id, data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PROJECT_TYPE_EDIT',
            payload: {
                name: '',
                description: '',
                percCapture: '',
                servicePrice: '',
                maxPercActivity: '',
                active: '',
                timetable: ''
            }
        })
        history.push('/config/project-type')
    })
}

let callConfigProjectTypeDelete = (id) => (dispatch) => {
    Helper.request(dispatch, 'DELETE', 'config/project-type/' + id, null, (data) => {
        toast.success(data.msg)
        dispatch(callConfigProjectTypeList);
    })
}


export { callConfigProjectTypeList, callConfigProjectTypeDetail, callConfigProjectTypePost, callConfigProjectTypePut, callConfigProjectTypeDelete }