import Helper from "./../../components/Helper"
import Swal from "sweetalert2";

let callSubscriber = (dispatch) => {
    Helper.request(dispatch, 'GET', 'subscriber', null, (data) => {
        dispatch({
            type: 'SUBSCRIBER_GET',
            payload: {
                plans: data.plans,
            }
        })
    }, null, false)
}

let callSubscriberPlan = (id) => (dispatch) => {
    Helper.request(dispatch, 'GET', 'subscriber/plan/' + id, null, (data) => {
        dispatch({
            type: 'SUBSCRIBER_PLAN',
            payload: {
                plan: data,
            }
        })
    }, null, true)
}

let callSubscriberRegister = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'subscriber/register', data, (data) => {
        Swal.fire({
            text: data.msg,
            icon: 'success',
        }).then((result) => {
            document.location.href = data.redir
        })
        
    })
}


export { callSubscriber, callSubscriberPlan, callSubscriberRegister }