import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { callConfigLead, callConfigLeadPut } from "./../../../actions/Config/Lead";

class ConfigLead extends Component {
    constructor(props) {
        super(props)

        let tab = [
            {
                description: 'Editar',
                link: '/config/lead',
                icon: 'fas fa-save',
                active: false,
            },
        ]

        props.handlerHeader({
            tab: tab,
            categoryId: 3,
            menuId: 17
        })

        props.dispatch(callConfigLead)

        this.state = {
            load: true,
            html: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.load && nextProps.store.configLead.html) {
            return ({
                ...prevState,
                html: nextProps.store.configLead.html,
                load: false,
            })
        }

        return null
    }

    save() {
        this.props.dispatch(callConfigLeadPut({
            html: this.state.html,
        }));
    }

    render() {
        return (
            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                <div className="card-custom">
                    <div className="card-body">
                        <h1 className="title-form">INFORMAÇÕES PRINCIPAIS</h1>
                        <div className="form-row">
                            <div className="col-md-12 form-group">
                                <label className="required">Código HTML</label>
                                <textarea className="form-control" rows={10} onChange={(e) => { this.setState({ html: e.target.value }) }} value={this.state.html}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="card-body form-btn">
                        <button className="btn btn-primary" type="submit"><i className="fas fa-save mr-1"></i>Alterar</button>
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ConfigLead))