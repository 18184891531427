import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callAuthPost } from "./../../actions/Auth";
import './../../styles/Login.css'

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            pass: '',
            visible: false,
        }
    }

    submit() {
        this.props.dispatch(callAuthPost({
            email: this.state.email,
            pass: this.state.pass,
        }))
    }

    toggleVisible() {
        if (this.state.visible) {
            this.setState({
                visible: false
            })
            return;
        }

        this.setState({
            visible: true
        })
    }

    doubleClickSupport() {
        this.props.history.push('/support/login')
    }

    render() {
        return (
            <div className="container-login d-flex align-items-stretch">
                <div className="sdk d-flex flex-column align-items-center">
                    <Link to={'/account/login'} className="logo"><img src={this.props.store.config.logoA} alt="Eloss" /></Link>
                    <h2 className="text-center mt-5" onDoubleClick={e => this.doubleClickSupport()}>Acessar o sistema</h2>
                    <div className="authfast-sdk">
                        <div className="box" style={{ width: '100%' }}>
                            <div className="login" style={{ flex: '1 1 0%', maxWidth: '340px' }}>
                                <div className="sign-up">
                                    <h1 className="text-center m-0 title-h1">Já sou cadastrado</h1> <span className="separator title-sep"></span>
                                    <form onSubmit={e => { e.preventDefault(); this.submit() }}>
                                        <div className="form-row">
                                            <div className="col-12 form-group input-group">
                                                <input type="text" placeholder="Digite seu e-mail" className="form-control" style={{ width: 'auto !important' }} onChange={(e) => { this.setState({ email: e.target.value }) }} value={this.state.email} />
                                            </div>
                                            <div className="col-md-12 form-group input-group">
                                                <input placeholder="Digite sua senha" type={this.state.visible ? 'text' : 'password'} className="form-control pass" style={{ width: 'auto !important' }} onChange={(e) => { this.setState({ pass: e.target.value }) }} value={this.state.pass} />
                                                <i className="input-group-text hide-pass" onClick={(e) => this.toggleVisible()}>
                                                    <i className={'fas ' + (this.state.visible ? 'fa-eye' : 'fa-eye-slash') + ' fa-2x'}></i>
                                                </i>
                                            </div>
                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-block btn-success">Continuar</button>
                                                <Link to={'/subscriber'} className="mt-1 float-left forget"><small>Cadastre-se!</small></Link>
                                                <Link to={'/dashboard'} className="mt-1 float-right forget"><small>Esqueceu a senha?</small></Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="mt-5 d-block bydev" href="https://ericteixeira.dev" rel="noreferrer" target="_blank">ericteixeira.dev</a>
                </div>
                <div className="img d-flex flex-column justify-content-end">
                    <img src={this.props.store.config.bgLoginA} alt="Eloss" className="img-fluid" />
                </div>
            </div>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(Login))