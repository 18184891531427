import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callConfigProjectSportCategoryDetail, callConfigProjectSportCategoryPost, callConfigProjectSportCategoryPut } from "./../../../actions/Config/ProjectSportCategory";

class ConfigProjectSportCategoryEdit extends Component {
    constructor(props) {
        super(props)

        let id = 0
        let add = true
        if (typeof (props.match.params.id) != 'undefined') {
            id = props.match.params.id
            add = false
        }

        let tab = [
            {
                description: 'Consulta',
                link: '/config/project-sport-category',
                icon: 'fas fa-cog',
                active: false,
            },
        ]

        if (add) {
            tab.push({
                description: 'Adicionar',
                link: '/config/project-sport-category/add',
                icon: 'fas fa-plus',
                active: true,
            })
        } else {
            tab.push({
                description: 'Editar',
                link: '/config/project-sport-category/edit/' + id,
                icon: 'fas fa-save',
                active: true,
            })
        }

        props.handlerHeader({
            tab: tab,
            categoryId: 4,
            menuId: 12
        })

        props.dispatch(callConfigProjectSportCategoryDetail(id))

        this.state = {
            add: add,
            load: true,
            id: id,
            name: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.add && prevState.load && nextProps.store.configProjectSportCategory.detail.name) {
            return ({
                ...prevState,
                name: nextProps.store.configProjectSportCategory.detail.name,
                load: false,
            })
        }

        if (prevState.add && prevState.load) {
            return ({
                ...prevState,
                load: false,
            })
        }

        return null
    }

    save() {
        if (this.state.add) {
            this.props.dispatch(callConfigProjectSportCategoryPost({
                name: this.state.name,
            }));
        } else {
            this.props.dispatch(callConfigProjectSportCategoryPut(this.state.id, {
                name: this.state.name,
            }));
        }
    }

    render() {
        return (
            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                <div className="card-custom">
                    <div className="card-body">
                        <h1 className="title-form">INFORMAÇÕES PRINCIPAIS</h1>
                        <div className="form-row">
                            <div className="col-md-4 form-group">
                                <label className="required">Nome</label>
                                <input type="text" className="form-control" onChange={(e) => { this.setState({ name: e.target.value }) }} value={this.state.name} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body form-btn">
                        <button className="btn btn-primary" type="submit"><i className="fas fa-save mr-1"></i>Salvar</button>
                        <Link to={'/config/project-sport-category'} className="btn btn-secondary ml-1"><i className="mr-1 fas fa-ban"></i>Cancelar</Link>
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ConfigProjectSportCategoryEdit))