import Helper from "./../../components/Helper"
import history from './../../routes/history'
import { toast } from 'react-toastify'

let callRegisterAdminList = (dispatch) => {
    let pg = Helper.httpGet('pg', '1')
    Helper.request(dispatch, 'GET', 'register/admin?pg=' + pg, null, (data) => {
        dispatch({
            type: 'ADMIN_LIST',
            payload: {
                rows: data.rows,
                pagination: data.pagination,
                total: data.total,
            }
        })
    }, null, false)
}

let callRegisterAdminDetail = (id) => (dispatch) => {
    Helper.request(dispatch, 'GET', 'register/admin/' + id, null, (data) => {
        dispatch({
            type: 'ADMIN_EDIT',
            payload: {
                name: data.name,
                email: data.email,
            }
        })
    }, null, false)
}

let callRegisterAdminPost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'register/admin', data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'ADMIN_EDIT',
            payload: {
                name: '',
                email: '',
            }
        })
        history.push('/register/admin')
    })
}

let callRegisterAdminPut = (id, data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'register/admin/' + id, data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'ADMIN_EDIT',
            payload: {
                name: '',
                email: '',
            }
        })
        history.push('/register/admin')
    })
}

let callRegisterAdminDelete = (id) => (dispatch) => {
    Helper.request(dispatch, 'DELETE', 'register/admin/' + id, null, (data) => {
        toast.success(data.msg)
        dispatch(callRegisterAdminList);
    })
}


export { callRegisterAdminList, callRegisterAdminDetail, callRegisterAdminPost, callRegisterAdminPut, callRegisterAdminDelete }