import Helper from "./../../components/Helper"
import { toast } from 'react-toastify'

let callConfigFreeDetail = (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/free', null, (data) => {
        dispatch({
            type: 'CONFIG_FREE',
            payload: {
                c10: data.c10,
                c11: data.c11,
                c12: data.c12,
                c13: data.c13,
                c14: data.c14,
                c15: data.c15,
                c16: data.c16,
                c17: data.c17,
                c18: data.c18,
                c19: data.c19,
                c20: data.c20,
                c21: data.c21,
                c22: data.c22,
                d10: data.d10,
                d11: data.d11,
                d12: data.d12,
                d13: data.d13,
                d14: data.d14,
                d15: data.d15,
                d16: data.d16,
                d17: data.d17,
                d18: data.d18,
                d19: data.d19,
                d20: data.d20,
                d21: data.d21,
                d22: data.d22,
            }
        })
    }, null, false)
}

let callConfigFreePut = (data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/free', data, (data) => {
        toast.success(data.msg)
    })
}


export { callConfigFreeDetail, callConfigFreePut }