import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callDashboard } from "./../actions/Dashboard";
import { PROFILE_ADMIN, PROFILE_CUSTOMER, PROFILE_COLLABORATOR } from './../constants/Config';

class Dashboard extends Component {
    constructor(props) {
        super(props)

        props.dispatch(callDashboard)

        this.state = {
            load: true,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.load) {
            switch (nextProps.store.session.profileId) {
                case PROFILE_ADMIN:
                    nextProps.handlerHeader({
                        tab: [
                            {
                                description: 'Dashboard',
                                link: '/dashboard',
                                icon: 'fas fa-cog',
                                active: true,
                            }
                        ],
                        categoryId: 1,
                        menuId: 1,
                        auth: true,
                    })
                    return {
                        load: false,
                    }
                case PROFILE_CUSTOMER:
                    nextProps.handlerHeader({
                        tab: [
                            {
                                description: 'Dashboard',
                                link: '/dashboard',
                                icon: 'fas fa-cog',
                                active: true,
                            }
                        ],
                        categoryId: 6,
                        menuId: 15,
                        auth: true,
                    })
                    return {
                        load: false,
                    }
                case PROFILE_COLLABORATOR:
                    return {
                        load: false,
                    }
                default:
                    return {
                        load: false,
                    }
            }
        }

        return null
    }

    render() {
        switch (this.props.store.session.profileId) {
            case PROFILE_ADMIN:
                return (
                    <div className="card-custom">
                        <div className="card-body">
                            <p className="card-text">Bem vindo ao sistema administrador</p>
                        </div>
                    </div>
                )
            case PROFILE_CUSTOMER:
                return (
                    <div className="card-custom">
                        <div className="card-body">
                            <div className="card">
                                <h5 className="card-header">Seus projetos</h5>
                                <div className="card-body">
                                    <div className="list">
                                        {this.props.store.dashboard.orders.map((a, i) => (
                                            <div className="list-item" key={'list-a-' + i}>
                                                <div className="title d-flex align-items-center">
                                                    <a href="#a" to={'/register/customer/edit/' + a.id} className="link-access">Compra - {a.id}</a>
                                                </div>
                                                <div className="content d-flex justify-content-between">
                                                    <div className="description">
                                                        <strong>Plano:</strong> {a.plan}<br />
                                                        <strong>Quantidade de itens:</strong> {a.qtyItems}<br />
                                                        {a.isPending ? <><strong>Link de pagamento:</strong> <a href={a.url} target="noopener">{a.url}</a><br /></> : ''}
                                                        <strong>TID:</strong> {a.tid}<br />
                                                        <strong>Total:</strong> {a.total}<br />
                                                        <strong>Status:</strong> <span style={{ color: a.statusColor }}>{a.status}</span><br />
                                                        <strong>Cadastro:</strong> {a.createdAt}
                                                    </div>
                                                    <div className="options d-flex flex-column p-2">
                                                        {a.isPaid ? <Link to={'/project-customer/add'} className="btn btn-sm btn-warning btn-list mb-1" title="Criar Projeto"><i className="fa fa-plus fa-white mr-2"></i>Criar Projeto</Link> : ''}
                                                        {a.isProject ? <Link to={'/project-customer/add'} className="btn btn-sm btn-primary btn-list mb-1" title="Gerenciar Projeto"><i className="fa fa-cog fa-white mr-2"></i>Gerenciar Projeto</Link> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case PROFILE_COLLABORATOR:
                return (
                    <div className="card-custom">
                        <div className="card-body">
                            <p className="card-text">Bem vindo ao sistema colaborador</p>
                        </div>
                    </div>
                )
            default:
                return (<>Perfil inválido!</>)
        }
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(Dashboard))