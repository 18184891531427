import Helper from "./../../components/Helper"
import history from './../../routes/history'
import { toast } from 'react-toastify'

let callConfigProjectSportList = (dispatch) => {
    let pg = Helper.httpGet('pg', '1')
    Helper.request(dispatch, 'GET', 'config/project-sport?pg=' + pg, null, (data) => {
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_LIST',
            payload: {
                rows: data.rows,
                pagination: data.pagination,
                total: data.total,
            }
        })
    }, null, false)
}

let callConfigProjectSportDetail = (id) => (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/project-sport/' + id, null, (data) => {
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_EDIT',
            payload: {
                name: data.name,
                description: data.description,
                typesSelected: data.typesSelected,
                active: data.active,
                types: data.types
            }
        })
    }, null, false)
}

let callConfigProjectSportPost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'config/project-sport', data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_EDIT',
            payload: {
                name: '',
                description: '',
                typesSelected: [],
                active: '',
                types: [],
            }
        })
        history.push('/config/project-sport')
    })
}

let callConfigProjectSportPut = (id, data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/project-sport/' + id, data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_EDIT',
            payload: {
                name: '',
                description: '',
                typesSelected: [],
                active: '',
                types: [],
            }
        })
        history.push('/config/project-sport')
    })
}

let callConfigProjectSportDelete = (id) => (dispatch) => {
    Helper.request(dispatch, 'DELETE', 'config/project-sport/' + id, null, (data) => {
        toast.success(data.msg)
        dispatch(callConfigProjectSportList);
    })
}


export { callConfigProjectSportList, callConfigProjectSportDetail, callConfigProjectSportPost, callConfigProjectSportPut, callConfigProjectSportDelete }