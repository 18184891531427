import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callSubscriber } from "./../../actions/Subscriber/SubscriberPlan";
import './../../styles/Subscriber.css'

class SubscriberPlan extends Component {
    constructor(props) {
        super(props)

        props.dispatch(callSubscriber);
    }

    render() {
        return (
            <>
                <header className="b1">
                    <div className="container container-custom">
                        <div className="row">
                            <div className="col-md-12 box-info">
                                <a href="tel:+5521964116930" className="info mr-4"><i className="fas fa-mobile-alt"></i>&nbsp;&nbsp;21 96411-6930</a>
                                <a href="tel:+552135476249" className="info mr-4"><i className="fas fa-phone-alt"></i>&nbsp;&nbsp;21 3547-6249</a>
                                <a href="https://api.whatsapp.com/send?phone=5521964116930&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20" className="info mr-4"><i className="fab fa-whatsapp"></i>&nbsp;&nbsp;Chamar no WhatsApp</a>
                            </div>
                        </div>
                    </div>
                </header>
                <header className="b2">
                    <div className="container container-custom">
                        <div className="row">
                            <div className="col-md-12 box-logo">
                                <a href="/" className="logo"><img src={this.props.store.config.logoB} alt="" className="img-fluid" /></a>
                            </div>
                        </div>
                    </div>
                </header>
                <header className="b3">
                    <div className="container container-custom">
                        <div className="row">
                            <div className="col-md-12 box-description">Garantimos a aprovação do projeto ou o valor investido devolvido</div>
                        </div>
                    </div>
                </header>
                <div className="container container-custom mt-5">
                    <h2 className="title-main text-center">Escolha o plano que melhor lhe atenda e tenha os melhores resultados.</h2>
                    <div className="row plan mt-5">
                        {this.props.store.subscriberPlan.plans.map((a, ai) => (
                            <div className="col-md-3" key={'plan-' + ai}>
                                <div className="plan-item">
                                    <div className="h" style={{ backgroundColor: a.background }}>
                                        <div className="title" style={{ color: a.color }}>{a.name}</div>
                                        <div className="qty" style={{ color: a.color }}>{a.qtyItems}</div>
                                    </div>
                                    <div className="b">
                                        <div className="installment">{a.installment}<span className="cent">{a.cent}</span></div>
                                        <div className="options">
                                            {a.options.map((b, bi) => (
                                                <div className="options-item" key={'option-' + bi}><i className={b.check ? 'fas fa-check' : 'fas fa-times'}></i> {b.description}</div>
                                            ))}
                                        </div>
                                        <div className="actions">
                                            <Link to={'/subscriber/register/' + a.id} type="button" className="enter">Faça seu projeto</Link>
                                        </div>
                                        <div className="price">{a.price}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(SubscriberPlan))