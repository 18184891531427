import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callRegisterAdminDetail, callRegisterAdminPost, callRegisterAdminPut } from "./../../../actions/Register/Admin";

class AdminEdit extends Component {
    constructor(props) {
        super(props)

        let id = 0
        let add = true
        if (typeof (props.match.params.id) != 'undefined') {
            id = props.match.params.id
            add = false
        }

        let tab = [
            {
                description: 'Consulta',
                link: '/register/admin',
                icon: 'fas fa-cog',
                active: false,
            },
        ]

        if (add) {
            tab.push({
                description: 'Adicionar',
                link: '/register/admin/add',
                icon: 'fas fa-plus',
                active: true,
            })
        } else {
            tab.push({
                description: 'Editar',
                link: '/register/admin/edit/' + id,
                icon: 'fas fa-save',
                active: true,
            })
        }

        props.handlerHeader({
            tab: tab,
            categoryId: 2,
            menuId: 2
        })

        props.dispatch(callRegisterAdminDetail(id))

        this.state = {
            add: add,
            load: true,
            id: id,
            name: '',
            email: '',
            pass: '',
            passConfirm: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.add && prevState.load && nextProps.store.admin.detail.name) {
            return ({
                ...prevState,
                name: nextProps.store.admin.detail.name,
                email: nextProps.store.admin.detail.email,
                load: false,
            })
        }

        if (prevState.add && prevState.load) {
            return ({
                ...prevState,
                load: false,
            })
        }

        return null
    }

    save() {
        if (this.state.add) {
            this.props.dispatch(callRegisterAdminPost({
                name: this.state.name,
                email: this.state.email,
                pass: this.state.pass,
                passConfirm: this.state.passConfirm
            }));
        } else {
            this.props.dispatch(callRegisterAdminPut(this.state.id, {
                name: this.state.name,
                email: this.state.email,
                pass: this.state.pass,
                passConfirm: this.state.passConfirm
            }));
        }
    }

    render() {
        return (
            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                <div className="card-custom">
                    <div className="card-body">
                        <h1 className="title-form">INFORMAÇÕES PRINCIPAIS</h1>
                        <div className="form-row">
                            <div className="col-md-3 form-group">
                                <label className={'required'}>Nome Completo</label>
                                <input type="text" className="form-control" onChange={(e) => { this.setState({ name: e.target.value }) }} value={this.state.name} />
                            </div>
                            <div className="col-md-3 form-group">
                                <label className="required">E-mail</label>
                                <input type="text" className="form-control" onChange={(e) => { this.setState({ email: e.target.value }) }} value={this.state.email} />
                            </div>
                            <div className="col-md-3 form-group">
                                <label className="required">Senha</label>
                                <input type="password" className="form-control" onChange={(e) => { this.setState({ pass: e.target.value }) }} value={this.state.pass} />
                            </div>
                            <div className="col-md-3 form-group">
                                <label className="required">Confirmaçao de Senha</label>
                                <input type="password" className="form-control" onChange={(e) => { this.setState({ passConfirm: e.target.value }) }} value={this.state.passConfirm} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body form-btn">
                        <button className="btn btn-primary" type="submit"><i className="fas fa-save mr-1"></i>Salvar</button>
                        <Link to={'/register/admin'} className="btn btn-secondary ml-1"><i className="mr-1 fas fa-ban"></i>Cancelar</Link>
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(AdminEdit))