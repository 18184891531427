import Helper from "./../../components/Helper"
import history from './../../routes/history'
import { toast } from 'react-toastify'

let callConfigProjectSportCategoryList = (dispatch) => {
    let pg = Helper.httpGet('pg', '1')
    Helper.request(dispatch, 'GET', 'config/project-sport-category?pg=' + pg, null, (data) => {
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_CATEGORY_LIST',
            payload: {
                rows: data.rows,
                pagination: data.pagination,
                total: data.total,
            }
        })
    }, null, false)
}

let callConfigProjectSportCategoryDetail = (id) => (dispatch) => {
    Helper.request(dispatch, 'GET', 'config/project-sport-category/' + id, null, (data) => {
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_CATEGORY_EDIT',
            payload: {
                name: data.name,
            }
        })
    }, null, false)
}

let callConfigProjectSportCategoryPost = (data) => (dispatch) => {
    Helper.request(dispatch, 'POST', 'config/project-sport-category', data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_CATEGORY_EDIT',
            payload: {
                name: '',
            }
        })
        history.push('/config/project-sport-category')
    })
}

let callConfigProjectSportCategoryPut = (id, data) => (dispatch) => {
    Helper.request(dispatch, 'PUT', 'config/project-sport-category/' + id, data, (data) => {
        toast.success(data.msg)
        dispatch({
            type: 'CONFIG_PROJECT_SPORT_CATEGORY_EDIT',
            payload: {
                name: '',
            }
        })
        history.push('/config/project-sport-category')
    })
}

let callConfigProjectSportCategoryDelete = (id) => (dispatch) => {
    Helper.request(dispatch, 'DELETE', 'config/project-sport-category/' + id, null, (data) => {
        toast.success(data.msg)
        dispatch(callConfigProjectSportCategoryList);
    })
}


export { callConfigProjectSportCategoryList, callConfigProjectSportCategoryDetail, callConfigProjectSportCategoryPost, callConfigProjectSportCategoryPut, callConfigProjectSportCategoryDelete }