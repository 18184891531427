import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callSupportCodePost } from "./../../actions/Support";
import InputMask from "./../../components/InputMask";
import './../../styles/BaseOut.css'
import './../../styles/Login.css'

class Code extends Component {
    constructor(props) {
        super(props)
        this.state = {
            code: '',
        }
    }

    login() {
        this.props.dispatch(callSupportCodePost({
            code: this.state.code,
        }))
    }

    render() {
        return (
            <div className="container-login d-flex align-items-stretch">
                <div className="sdk d-flex flex-column align-items-center">
                    <Link to={'/account/login'} className="logo"><img src={this.props.store.config.logoA} alt="Eloss" /></Link>
                    <h2 className="text-center mt-5">Código de Acesso</h2>
                    <form onSubmit={e => { e.preventDefault(); this.login() }} className="authfast-sdk">
                        <div className="login">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <InputMask mask="num" value={this.state.code} className="form-control text-center" style={{ borderRightWidth: '2px' }} placeholder="Digite seu o código de autenticação" onChange={(v) => { this.setState({ code: v }) }} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-success btn-block">Verificar</button>
                        </div>
                    </form>
                    <a className="mt-5 d-block bydev" href="https://ericteixeira.dev" rel="noreferrer" target="_blank">ericteixeira.dev</a>
                </div>
                <div className="img d-flex flex-column justify-content-end">
                    <img src={this.props.store.config.bgLoginA} alt="Eloss" className="img-fluid" />
                </div>
            </div>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(Code))