import { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callConfigProjectSportOfficeDetail, callConfigProjectSportOfficePost, callConfigProjectSportOfficePut } from "./../../../actions/Config/ProjectSportOffice";
import { EDITOR_KEY } from "./../../../constants/Config";
import { Editor } from "@tinymce/tinymce-react";

class ConfigProjectSportOfficeEdit extends Component {
    constructor(props) {
        super(props)

        let id = 0
        let add = true
        if (typeof (props.match.params.id) != 'undefined') {
            id = props.match.params.id
            add = false
        }

        let tab = [
            {
                description: 'Consulta',
                link: '/config/project-sport-office',
                icon: 'fas fa-cog',
                active: false,
            },
        ]

        if (add) {
            tab.push({
                description: 'Adicionar',
                link: '/config/project-sport-office/add',
                icon: 'fas fa-plus',
                active: true,
            })
        } else {
            tab.push({
                description: 'Editar',
                link: '/config/project-sport-office/edit/' + id,
                icon: 'fas fa-save',
                active: true,
            })
        }

        props.handlerHeader({
            tab: tab,
            categoryId: 4,
            menuId: 13
        })

        props.dispatch(callConfigProjectSportOfficeDetail(id))

        this.state = {
            add: add,
            load: true,
            id: id,
            name: '',
            description: '',
            formation: '',
            assignment: '',
            activity: '',
            sports: [],
            active: true,
        }

        this.editorDescription = createRef()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.add && prevState.load && nextProps.store.configProjectSportOffice.detail.name) {
            return ({
                ...prevState,
                name: nextProps.store.configProjectSportOffice.detail.name,
                description: nextProps.store.configProjectSportOffice.detail.description,
                formation: nextProps.store.configProjectSportOffice.detail.formation,
                assignment: nextProps.store.configProjectSportOffice.detail.assignment,
                activity: nextProps.store.configProjectSportOffice.detail.activity,
                sports: nextProps.store.configProjectSportOffice.detail.sports,
                active: nextProps.store.configProjectSportOffice.detail.active,
                load: false,
            })
        }

        if (prevState.add && prevState.load) {
            return ({
                ...prevState,
                load: false,
            })
        }

        return null
    }

    save() {
        let data = {
            name: this.state.name,
            description: this.state.description,
            formation: this.state.formation,
            assignment: this.state.assignment,
            activity: this.state.activity,
            sports: this.state.sports,
            active: this.state.active,
        };

        if (this.state.add) {
            this.props.dispatch(callConfigProjectSportOfficePost(data));
        } else {
            this.props.dispatch(callConfigProjectSportOfficePut(this.state.id, data));
        }
    }

    render() {
        return (
            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                <div className="card-custom">
                    <div className="card-body">
                        <h1 className="title-form">INFORMAÇÕES PRINCIPAIS</h1>
                        <div className="form-row">
                            <div className="col-md-4 form-group">
                                <label className="required">Nome</label>
                                <input type="text" className="form-control" onChange={(e) => { this.setState({ name: e.target.value }) }} value={this.state.name} />
                            </div>
                            <div className="col-md-12 form-group">
                                <div className="bg-secondary p-3 text-white">
                                    <h4 className="text-white">IMPORTANTE</h4>
                                    No campo <strong className="text-white">"Descrição"</strong> use os meta dados abaixo para compor o texto:
                                    <br />
                                    [[duracao-projeto]] - Exibe o tempo do projeto em meses.<br />
                                    [[horas-semanais]] - Exibe a quantidade de horas semanais da função no projeto.<br />
                                    [[quantidade-profissionais]] - Exibe a quantidade de profissionais que serão alocados no projeto desta mesma função<br />
                                    [[regime-contratacao]] - Exibe o texto "RPA" para projetos com duração até 3 meses ou "CLT" para projetos acima de 3 meses)
                                </div>
                            </div>
                            <div className="col-md-12 form-group">
                                <label>Descrição</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(_, editor) => this.editorDescription.current = editor}
                                    initialValue={this.props.store.configProjectSportOffice.detail.description}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label className="required">Formação</label>
                                <textarea className="form-control" onChange={(e) => { this.setState({ formation: e.target.value }) }} value={this.state.formation}></textarea>
                            </div>
                            <div className="col-md-12 form-group">
                                <label className="required">Atribuições</label>
                                <textarea className="form-control" onChange={(e) => { this.setState({ assignment: e.target.value }) }} value={this.state.assignment}></textarea>
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="required">Ativo</label>
                                <select className="form-control" value={this.state.active} onChange={(e) => { this.setState({ active: e.target.value === '1' ? true : false }) }}>
                                    <option value="">Selecione</option>
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="required">Tipo de Atividade</label>
                                <select className="form-control" value={this.state.activity} onChange={(e) => { this.setState({ active: e.target.activity }) }}>
                                    <option value="">Selecione</option>
                                    <option value="1">Atividade Meio</option>
                                    <option value="2">Atividade Fim</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="card-body form-btn">
                        <button className="btn btn-primary" type="submit"><i className="fas fa-save mr-1"></i>Salvar</button>
                        <Link to={'/config/project-sport-office'} className="btn btn-secondary ml-1"><i className="mr-1 fas fa-ban"></i>Cancelar</Link>
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ConfigProjectSportOfficeEdit))