import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { callConfigProjectSportDetail, callConfigProjectSportPost, callConfigProjectSportPut } from "./../../../actions/Config/ProjectSport";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

class ConfigProjectSportEdit extends Component {
    constructor(props) {
        super(props)

        let id = 0
        let add = true
        if (typeof (props.match.params.id) != 'undefined') {
            id = props.match.params.id
            add = false
        }

        let tab = [
            {
                description: 'Consulta',
                link: '/config/project-sport',
                icon: 'fas fa-cog',
                active: false,
            },
        ]

        if (add) {
            tab.push({
                description: 'Adicionar',
                link: '/config/project-sport/add',
                icon: 'fas fa-plus',
                active: true,
            })
        } else {
            tab.push({
                description: 'Editar',
                link: '/config/project-sport/edit/' + id,
                icon: 'fas fa-save',
                active: true,
            })
        }

        props.handlerHeader({
            tab: tab,
            categoryId: 4,
            menuId: 11
        })

        props.dispatch(callConfigProjectSportDetail(id))

        this.state = {
            add: add,
            load: true,
            id: id,
            name: '',
            description: '',
            typesSelected: [],
            active: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.add && prevState.load && nextProps.store.configProjectSport.detail.name) {
            return ({
                ...prevState,
                name: nextProps.store.configProjectSport.detail.name,
                description: nextProps.store.configProjectSport.detail.description,
                typesSelected: nextProps.store.configProjectSport.detail.typesSelected,
                active: nextProps.store.configProjectSport.detail.active,
                load: false,
            })
        }

        if (prevState.add && prevState.load) {
            return ({
                ...prevState,
                load: false,
            })
        }

        return null
    }

    save() {
        if (this.state.add) {
            this.props.dispatch(callConfigProjectSportPost({
                name: this.state.name,
                description: this.state.description,
                typesSelected: this.state.typesSelected,
                active: this.state.active,
            }));
        } else {
            this.props.dispatch(callConfigProjectSportPut(this.state.id, {
                name: this.state.name,
                description: this.state.description,
                typesSelected: this.state.typesSelected,
                active: this.state.active
            }));
        }
    }

    render() {
        return (
            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                <div className="card-custom">
                    <div className="card-body">
                        <h1 className="title-form">INFORMAÇÕES PRINCIPAIS</h1>
                        <div className="form-row">
                            <div className="col-md-4 form-group">
                                <label className="required">Nome</label>
                                <input type="text" className="form-control" onChange={(e) => { this.setState({ name: e.target.value }) }} value={this.state.name} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label className="required">Descrição</label>
                                <textarea className="form-control" onChange={(e) => { this.setState({ description: e.target.value }) }} value={this.state.description}></textarea>
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="required">Tipos de Manifestação Esportiva</label>
                                <Typeahead
                                    id="typesSelected"
                                    labelKey="description"
                                    multiple
                                    onChange={(e) => this.setState({ typesSelected: e })}
                                    options={this.props.store.configProjectSport.detail.types}
                                    placeholder="Digite os tipos..."
                                    selected={this.state.typesSelected}
                                />
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="required">Ativo</label>
                                <select className="form-control" value={this.state.active} onChange={(e) => { this.setState({ active: e.target.value }) }}>
                                    <option value="">Selecione</option>
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="card-body form-btn">
                        <button className="btn btn-primary" type="submit"><i className="fas fa-save mr-1"></i>Salvar</button>
                        <Link to={'/config/project-sport'} className="btn btn-secondary ml-1"><i className="mr-1 fas fa-ban"></i>Cancelar</Link>
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ConfigProjectSportEdit))