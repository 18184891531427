import { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { callConfigFreeDetail, callConfigFreePut } from "./../../../actions/Config/Free";
import { Editor } from '@tinymce/tinymce-react';
import { EDITOR_KEY } from "./../../../constants/Config";

class ConfigGeneral extends Component {
    constructor(props) {
        super(props)

        let tab = [
            {
                description: 'Editar',
                link: '/config/free',
                icon: 'fas fa-save',
                active: false,
            },
        ]

        props.handlerHeader({
            tab: tab,
            categoryId: 3,
            menuId: 6
        })

        props.dispatch(callConfigFreeDetail)

        this.editor11 = createRef()
        this.editor13 = createRef()
        this.editor15 = createRef()
        this.editor16 = createRef()
        this.editor17 = createRef()
        this.editor20 = createRef()
        this.editor21 = createRef()
        this.editor22 = createRef()

        this.state = {
            load: true,
            c10: '',
            c11: '',
            c12: '',
            c13: '',
            c14: '',
            c15: '',
            c16: '',
            c17: '',
            c18: '',
            c19: '',
            c20: '',
            c21: '',
            c22: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.load && nextProps.store.configFree.detail.c10) {
            return ({
                ...prevState,
                c10: nextProps.store.configFree.detail.c10,
                c11: nextProps.store.configFree.detail.c11,
                c12: nextProps.store.configFree.detail.c12,
                c13: nextProps.store.configFree.detail.c13,
                c14: nextProps.store.configFree.detail.c14,
                c15: nextProps.store.configFree.detail.c15,
                c16: nextProps.store.configFree.detail.c16,
                c17: nextProps.store.configFree.detail.c17,
                c18: nextProps.store.configFree.detail.c18,
                c19: nextProps.store.configFree.detail.c19,
                c20: nextProps.store.configFree.detail.c20,
                c21: nextProps.store.configFree.detail.c21,
                c22: nextProps.store.configFree.detail.c22,
                load: false,
            })
        }

        return null
    }

    save() {
        this.props.dispatch(callConfigFreePut({
            c10: this.state.c10,
            c11: this.state.c11,
            c12: this.state.c12,
            c13: this.state.c13,
            c14: this.state.c14,
            c15: this.state.c15,
            c16: this.state.c16,
            c17: this.state.c17,
            c18: this.state.c18,
            c19: this.state.c19,
            c20: this.state.c20,
            c21: this.state.c21,
            c22: this.state.c22,
        }));
    }

    render() {
        return (
            <form onSubmit={e => { e.preventDefault(); this.save() }}>
                <div className="card-custom">
                    <div className="card-body">
                        <h1 className="title-form">INFORMAÇÕES PRINCIPAIS</h1>
                        <div className="form-row">
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d10}</label>
                                <input type="text" className="form-control" defaultValue={this.props.store.configFree.detail.c10} onChange={(e) => { this.setState({ c10: e.target.value }) }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d11}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor11.current = editor}
                                    initialValue={this.props.store.configFree.detail.c11}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d12}</label>
                                <input type="text" className="form-control" defaultValue={this.props.store.configFree.detail.c12} onChange={(e) => { this.setState({ c12: e.target.value }) }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d13}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor13.current = editor}
                                    initialValue={this.props.store.configFree.detail.c13}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d14}</label>
                                <input type="text" className="form-control" defaultValue={this.props.store.configFree.detail.c14} onChange={(e) => { this.setState({ c14: e.target.value }) }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d15}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor15.current = editor}
                                    initialValue={this.props.store.configFree.detail.c15}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d16}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor16.current = editor}
                                    initialValue={this.props.store.configFree.detail.c16}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d17}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor17.current = editor}
                                    initialValue={this.props.store.configFree.detail.c17}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d18}</label>
                                <input type="text" className="form-control" defaultValue={this.props.store.configFree.detail.c18} onChange={(e) => { this.setState({ c18: e.target.value }) }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d19}</label>
                                <input type="text" className="form-control" defaultValue={this.props.store.configFree.detail.c19} onChange={(e) => { this.setState({ c19: e.target.value }) }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d20}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor20.current = editor}
                                    initialValue={this.props.store.configFree.detail.c20}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d21}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor21.current = editor}
                                    initialValue={this.props.store.configFree.detail.c21}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{this.props.store.configFree.detail.d22}</label>
                                <Editor
                                    apiKey={EDITOR_KEY}
                                    onInit={(evt, editor) => this.editor22.current = editor}
                                    initialValue={this.props.store.configFree.detail.c22}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-body form-btn">
                        <button className="btn btn-primary" type="submit"><i className="fas fa-save mr-1"></i>Alterar</button>
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = function (store) {
    return {
        store: store
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ConfigGeneral))