import Dashboard from "./../modules/Dashboard"
import Login from "./../modules/Account/Login"
import SupportLogin from "./../modules/Support/Login"
import SupportCode from "./../modules/Support/Code"
import CustomerList from "./../modules/Register/Customer/CustomerList"
import CustomerEdit from "./../modules/Register/Customer/CustomerEdit"
import AdminList from "./../modules/Register/Admin/AdminList"
import AdminEdit from "./../modules/Register/Admin/AdminEdit"
import Middleware from "./../modules/Middleware"
import CollaboratorList from "./../modules/Register/Collaborator/CollaboratorList"
import CollaboratorEdit from "./../modules/Register/Collaborator/CollaboratorEdit"
import ConfigGeneral from "./../modules/Config/General/ConfigGeneral"
import ConfigFree from "./../modules/Config/Free/ConfigFree"
import PlanList from "./../modules/Config/Plan/PlanList"
import PlanEdit from "./../modules/Config/Plan/PlanEdit"
import ConfigBankList from "./../modules/Config/Bank/ConfigBankList"
import ConfigBankEdit from "./../modules/Config/Bank/ConfigBankEdit"
import ConfigProjectTypeList from "./../modules/Config/ProjectType/ConfigProjectTypeList"
import ConfigProjectTypeEdit from "./../modules/Config/ProjectType/ConfigProjectTypeEdit"
import ConfigProjectStatusList from "./../modules/Config/ProjectStatus/ConfigProjectStatusList"
import ConfigProjectStatusEdit from "./../modules/Config/ProjectStatus/ConfigProjectStatusEdit"
import ConfigProjectSportList from "./../modules/Config/ProjectSport/ConfigProjectSportList"
import ConfigProjectSportEdit from "./../modules/Config/ProjectSport/ConfigProjectSportEdit"
import ConfigProjectSportCategoryList from "./../modules/Config/ProjectSportCategory/ConfigProjectSportCategoryList"
import ConfigProjectSportCategoryEdit from "./../modules/Config/ProjectSportCategory/ConfigProjectSportCategoryEdit"
import SubscriberPlan from "./../modules/Subscriber/SubscriberPlan"
import SubscriberRegister from "./../modules/Subscriber/SubscriberRegister"
import ConfigPayment from "./../modules/Config/Payment/ConfigPayment"
import ConfigLead from "./../modules/Config/Lead/ConfigLead"
import ConfigProjectSportOfficeList from "./../modules/Config/ProjectSportOffice/ConfigProjectSportOfficeList"
import ConfigProjectSportOfficeEdit from "./../modules/Config/ProjectSportOffice/ConfigProjectSportOfficeEdit"

const routes = [
    {
        exact: true,
        path: '/',
        component: Middleware,
        routes: [
            {
                exact: false,
                path: '/account/login',
                component: Login,
                auth: false,
            },
            {
                exact: false,
                path: '/support/login',
                component: SupportLogin,
                auth: false,
            },
            {
                exact: false,
                path: '/support/code',
                component: SupportCode,
                auth: false,
            },
            {
                exact: false,
                path: '/register/customer/edit/:id',
                component: CustomerEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/register/customer/add',
                component: CustomerEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/register/customer',
                component: CustomerList,
                auth: true,
            },
            {
                exact: false,
                path: '/register/admin/edit/:id',
                component: AdminEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/register/admin/add',
                component: AdminEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/register/admin',
                component: AdminList,
                auth: true,
            },
            {
                exact: false,
                path: '/register/collaborator/edit/:id',
                component: CollaboratorEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/register/collaborator/add',
                component: CollaboratorEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/register/collaborator',
                component: CollaboratorList,
                auth: true,
            },
            {
                exact: false,
                path: '/config/plan/edit/:id',
                component: PlanEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/plan',
                component: PlanList,
                auth: true,
            },
            {
                exact: false,
                path: '/config/bank/edit/:id',
                component: ConfigBankEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/bank/add',
                component: ConfigBankEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/bank',
                component: ConfigBankList,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-type/edit/:id',
                component: ConfigProjectTypeEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-type/add',
                component: ConfigProjectTypeEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-type',
                component: ConfigProjectTypeList,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-status/edit/:id',
                component: ConfigProjectStatusEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-status/add',
                component: ConfigProjectStatusEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-status',
                component: ConfigProjectStatusList,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-sport/edit/:id',
                component: ConfigProjectSportEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-sport/add',
                component: ConfigProjectSportEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-sport',
                component: ConfigProjectSportList,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-sport-category/edit/:id',
                component: ConfigProjectSportCategoryEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-sport-category/add',
                component: ConfigProjectSportCategoryEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-sport-category',
                component: ConfigProjectSportCategoryList,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-sport-office/edit/:id',
                component: ConfigProjectSportOfficeEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-sport-office/add',
                component: ConfigProjectSportOfficeEdit,
                auth: true,
            },
            {
                exact: false,
                path: '/config/project-sport-office',
                component: ConfigProjectSportOfficeList,
                auth: true,
            },
            {
                exact: false,
                path: '/config/free',
                component: ConfigFree,
                auth: true,
            },
            {
                exact: false,
                path: '/config/lead',
                component: ConfigLead,
                auth: true,
            },
            {
                exact: false,
                path: '/config/payment',
                component: ConfigPayment,
                auth: true,
            },
            {
                exact: false,
                path: '/config/general',
                component: ConfigGeneral,
                auth: true,
            },
            {
                exact: false,
                path: '/subscriber/register/:id',
                component: SubscriberRegister,
                auth: false,
            },
            {
                exact: false,
                path: '/subscriber',
                component: SubscriberPlan,
                auth: false,
            },
            
            {
                exact: true,
                path: '/dashboard',
                component: Dashboard,
                auth: true,
            },
            {
                exact: true,
                path: '/',
                component: Dashboard,
                auth: true,
            }
        ],
    },
]

export default routes